import React, { Fragment, useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Media from "react-media";
import { Alert, Col, Row, Tabs, Tag, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import moment, { Moment } from "moment";

import Page from "./Page";
import OrderDetails from "./OrderDetails";
import PendingOrderCol from "./PendingOrderCol";
import StartedOrderCol from "./StartedOrderCol";
import DoneOrderCol from "./DoneOrderCol";
import { shortDisplayTime } from "./TypedUtil";

import {
  getCredentials,
  getOrderState,
  WS_URL,
  ORDER_TYPE_COLORS,
  STATUS,
} from "./Util";
import styles from "./css/ControlStation.module.css";

const { Paragraph } = Typography;
const { TabPane } = Tabs;

const getItemActionStatus = (item) => {
  let allPending = true;
  let allDone = true;
  for (let i = 0; i < item.actions.length; i++) {
    const action = item.actions[i];
    if (!action.pending_time && !action.start_time && !action.done_time) {
      // Each action for each item in started order should always have at least
      // pending_time set.
      return STATUS.UNDEFINED;
    } else if (action.pending_time && !action.start_time && !action.done_time) {
      allDone = false;
    } else if (action.pending_time && action.start_time && !action.done_time) {
      allPending = false;
      allDone = false;
    } else {
      // action.pending_time && action.start_time && action.done_time
      allPending = false;
    }
  }
  if (allPending) {
    return STATUS.PENDING;
  }
  if (allDone) {
    return STATUS.DONE;
  }
  return STATUS.STARTED;
};

const getNumItemsByStatus = (order) => {
  let percDoneActions = 0;
  let itemActionStatuses = new Map();
  if (order.items && order.items.length > 0) {
    let numActions = 0;
    let numDoneActions = 0;
    order.items.forEach((item) => {
      let itemActionStatus = STATUS.DONE; // Default status for items with no actions
      if (item.actions) {
        itemActionStatus = getItemActionStatus(item);
        numActions += item.actions.length;
        item.actions.forEach((action) => {
          if (action.done_time) {
            numDoneActions++;
          }
        });
      }
      itemActionStatuses.set(item.id, itemActionStatus);
    });
    percDoneActions = Math.trunc((numDoneActions / numActions) * 100);
  }
  return { itemActionStatuses, percDoneActions };
};

const collapseItems = (order) => {
  if (!order.items || order.items.length <= 0) {
    return { items: order.items };
  }
  const itemMap = new Map();
  order.items.forEach((item) => {
    const itemKey = item.attributes_id;
    if (!itemMap.has(itemKey)) {
      itemMap.set(itemKey, {
        ...item,
        id: itemKey,
        itemIDs: new Set(),
        qty: 0,
        redeemed: false,
      });
    }
    const collapsedItem = itemMap.get(itemKey);
    collapsedItem.redeemed |= item.redeemed;
    collapsedItem.qty++;
    collapsedItem.itemIDs.add(item.id);
  });
  return { items: Array.from(itemMap.values()) };
};

// delta is an OrderUpdate object from the backend.
// https://github.com/TicketRails/backend/blob/8dfdecdf5c272c2969384e468a0ab6ee0ae8cb3c/services/api/websocket/schema.go#L9
const updateOrdersByState = (oldState, delta) => {
  var newState = { ...oldState };

  // Get all the order IDs that changed
  var changedIDs = new Set();
  if (delta.updated) {
    delta.updated.forEach((order) => {
      changedIDs.add(order.id);
    });
  }
  if (delta.deleted) {
    delta.deleted.forEach((id) => changedIDs.add(id));
  }

  // Filter out done orders before 30 minutes ago and
  // remove all changed orders from the new state.
  const doneAfter = new Date();
  doneAfter.setTime(doneAfter.getTime() - 30 * 60 * 1000);
  ["pending", "started", "done"].forEach((k) => {
    const removedIndices = new Set();
    for (let i = 0; i < oldState[k].length; i++) {
      const order = oldState[k][i];
      if (
        changedIDs.has(order.id) ||
        (order.done_time && new Date(order.done_time) < doneAfter)
      ) {
        removedIndices.add(i);
      }
    }

    newState[k] = newState[k].filter((order, i) => !removedIndices.has(i));
  });

  // Add all updated orders.
  if (delta.updated) {
    delta.updated.forEach((order) => {
      const k = getOrderState(order);
      if (k === "started") {
        order = {
          ...order,
          ...getNumItemsByStatus(order),
        };
      } else {
        order = { ...order, ...collapseItems(order) };
      }
      newState[k].push(order);
    });
  }

  // Make sure everything is sorted by priority.
  const comparePriority = (p1, p2) => -1 * (p1 - p2); // sort in descending order
  var sortKeys = {
    pending: {
      priority: comparePriority,
    },
    started: {
      priority: comparePriority,
      pos_order_name: (n1, n2) => n1.localeCompare(n2),
    },
    done: {},
  };
  Object.entries(sortKeys).forEach(([state, keys]) => {
    newState[state].sort((order1, order2) => {
      for (let key in keys) {
        if (order1[key] !== order2[key]) {
          return keys[key](order1[key], order2[key]);
        }
      }
      if (order1.estimated_pickup_time !== order2.estimated_pickup_time) {
        return (
          Date.parse(order1.estimated_pickup_time) -
          Date.parse(order2.estimated_pickup_time)
        );
      }
      if (order1.create_time !== order2.create_time) {
        return Date.parse(order1.create_time) - Date.parse(order2.create_time);
      }
      return order1.id.localeCompare(order2.id);
    });
  });

  return newState;
};

const websocketDisconnectBanner = (
  <Alert
    message="無法連線"
    description="重新嘗試中..."
    type="error"
    showIcon
    className={styles.websocketDisconnect}
  />
);

export default () => {
  const restaurantID = sessionStorage.getItem("restaurant_id");
  if (!restaurantID) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  const [ordersByState, setOrdersByState] = useState({
    pending: [],
    started: [],
    done: [],
  });
  const [websocketDisconnect, setWebsocketDisconnect] = useState(false);
  const latestUpdateRef = useRef(null);

  useEffect(() => {
    const openWebSocket = () => {
      // Use cookie to pass auth info.
      // https://stackoverflow.com/questions/4361173/http-headers-in-websockets-client-api
      document.cookie = `x-tr-websocket-basic-auth=${getCredentials()}`;
      const url = `${WS_URL}/v1/ws/r/${restaurantID}/orders`;
      const ws = new W3CWebSocket(url);

      let idlePing = null;

      ws.onopen = () => {
        setWebsocketDisconnect(false);

        // Clear state since the backend only returns the latest state, and our
        // local state could be very stale if we were disconnected.
        setOrdersByState({ pending: [], started: [], done: [] });

        // Send a message every 10 seconds to keep the TCP connection busy so it
        // doesn't get killed by the load balancer.
        idlePing = setInterval(() => {
          ws.send("1");
        }, 10 * 1000);
      };

      ws.onclose = () => {
        // Websocket closed.
        // TODO: add better backoff logic to refresh after websocket disconnect
        if (idlePing) {
          clearInterval(idlePing);
        }
        setWebsocketDisconnect(true);

        // Try opening the websocket again.
        setTimeout(() => {
          openWebSocket();
        }, 3000);
      };

      ws.onmessage = (evt) => {
        const message = JSON.parse(evt.data);
        latestUpdateRef.current = Date.parse(message["ts"]);
        setOrdersByState((prev) => updateOrdersByState(prev, message));
      };
    };

    openWebSocket();
  }, [restaurantID]);

  const renderOrder = (order, totalItems) => {
    const orderTypeColor = ORDER_TYPE_COLORS[order.pos_order_type.toLowerCase()]
      ? ORDER_TYPE_COLORS[order.pos_order_type.toLowerCase()]
      : "";
    let orderPickupTime = null;
    if (order.estimated_pickup_time) {
      orderPickupTime = new Date(order.estimated_pickup_time);
    }

    // Display flashing warning if an order is in started state for more than
    // 15 min. This indicates that perhaps user forgot to click "done", keeping
    // the customer waiting.
    const startTime = (order.start_time != null && order.start_time !== "") ? moment(order.start_time) : null;
    const flashWarning = moment() > (startTime ?? moment()).add(15, 'm').toDate();

    return (
      <div className={flashWarning ? styles.orderFlashing : styles.order}>
        <div>
          {order.pos_order_name ? order.pos_order_name : order.pos_order_id}
          {order.pos_order_type && (
            <Tag color={orderTypeColor} className={styles.orderType}>
              {order.pos_order_type}
            </Tag>
          )}
          {(totalItems === 0 || !order.pos_order_name) && (
            <ExclamationCircleOutlined className={styles.orderError} />
          )}
        </div>
        <div>
          <span>
            {totalItems} {totalItems === 1 ? "item" : "items"}
          </span>
          {order.customer && order.customer.name && (
            <span>, Customer: {order.customer.name}</span>
          )}
        </div>
        {
          // Cut off long notes to avoid cluttering the page. Users may view the
          // full note in the order detail.
          order.notes && (
            <Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              style={{ margin: "0px" }}>
              {order.notes}
            </Paragraph>
          )
        }
        {orderPickupTime && (
          <div>取餐時間: {shortDisplayTime(orderPickupTime)}</div>
        )}
      </div>
    );
  };

  const renderCollapsedOrder = (order) => {
    const totalItems =
      order.items && order.items.length > 0
        ? order.items.reduce((acc, item) => acc + item.qty, 0)
        : 0;
    return renderOrder(order, totalItems);
  };

  const renderOrderTitle = (order) =>
    `Order ${order.pos_order_name ? order.pos_order_name : order.pos_order_id}`;

  // Split pending orders into:
  // - immediate: ready to be startred
  // - on deck: to be started within the next 60 minutes
  // - today: to be started after 60 minutes and within 24 hours
  // - future: to be started after 24 hours
  // We use display_after time since some orders may be manually set to display
  // at a future date (i.e. a future order made through phone/clover).
  const [immediateCutoff, setImmediateCutoff] = useState(moment());
  const onDeckIntervalMinutes = 60;
  const onDeckCutoff = immediateCutoff.clone().add(onDeckIntervalMinutes, "minutes");
  const todayIntervalHours = 24;
  const todayCutoff = immediateCutoff.clone().add(todayIntervalHours, "hours");
  const immediatePendingOrders = ordersByState["pending"].filter(order => {
    if (order.display_after) {
      return moment(order.display_after).isBefore(immediateCutoff);
    }
    // Order without display_after is immediate.
    return true;
  });
  const onDeckPendingOrders = ordersByState["pending"].filter(order => {
    if (order.display_after) {
      const da = moment(order.display_after);
      return da.isSameOrAfter(immediateCutoff) && da.isBefore(onDeckCutoff);
    }
    return false;
  });
  const todayPendingOrders = ordersByState["pending"].filter(order => {
    if (order.display_after) {
      const da = moment(order.display_after);
      return da.isSameOrAfter(onDeckCutoff) && da.isBefore(todayCutoff);
    }
    return false;
  });
  const futurePendingOrders = ordersByState["pending"].filter(order => {
    if (order.display_after) {
      return moment(order.display_after).isSameOrAfter(todayCutoff);
    }
    return false;
  });

  // Hack: Refresh the immediate cutoff time every 10 seconds to make sure
  // immediate and future pending orders are updated as time moves on.
  useEffect(() => {
    const interval = setInterval(() => {
      setImmediateCutoff(moment());
    }, 10 * 1000);
    return () => { clearInterval(interval) };
  }, []);

  const pageTitle = `外場訂單控制台`;

  const [activeTabKey, setActiveTabKey] = useState("pending-immediate");

  // Requested by O2 - reset to pending-immediate tab automatically.
  const activeTabInterval = useRef(null);
  const onActiveTabChange = (key) => {
    if (key !== "pending-immediate") {
      if (activeTabInterval.current) {
        clearInterval(activeTabInterval.current);
      }
      activeTabInterval.current = setInterval(() => {
        setActiveTabKey("pending-immediate");
      }, 15 * 1000);
    }
    setActiveTabKey(key);
  }

  return (
    <Page title={pageTitle}>
      {websocketDisconnect && websocketDisconnectBanner}
      <Row gutter={16}>
        <Col span={8}>
          <Tabs activeKey={activeTabKey} onChange={onActiveTabChange} >
            <TabPane
              tab={
                <span>
                  立刻
                  {immediatePendingOrders && (
                    <span> ({immediatePendingOrders.length})</span>
                  )}
                </span>
              }
              key="pending-immediate"
            >
              <PendingOrderCol
                orders={immediatePendingOrders}
                renderOrder={renderCollapsedOrder}
                renderOrderTitle={renderOrderTitle}
                renderOrderDetails={(order) => (
                  <OrderDetails
                    order={order}
                    pending
                    restaurantID={restaurantID}
                  />
                )}
                restaurantID={restaurantID}
                elemsMaxHeight="55vh"
                playSound
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  {onDeckIntervalMinutes}分鐘內
                  {onDeckPendingOrders && (
                    <span> ({onDeckPendingOrders.length})</span>
                  )}
                </span>
              }
              key="pending-on-deck"
            >
              <PendingOrderCol
                orders={onDeckPendingOrders}
                renderOrder={renderCollapsedOrder}
                renderOrderTitle={renderOrderTitle}
                renderOrderDetails={(order) => (
                  <OrderDetails
                    order={order}
                    pending
                    restaurantID={restaurantID}
                  />
                )}
                restaurantID={restaurantID}
                elemsMaxHeight="55vh"
                bgColor="#fc1703"
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  {">" + onDeckIntervalMinutes.toString() + "分鐘"}
                  {todayPendingOrders && (
                    <span> ({todayPendingOrders.length})</span>
                  )}
                </span>
              }
              key="pending-today"
            >
              <PendingOrderCol
                orders={todayPendingOrders}
                renderOrder={renderCollapsedOrder}
                renderOrderTitle={renderOrderTitle}
                renderOrderDetails={(order) => (
                  <OrderDetails
                    order={order}
                    pending
                    restaurantID={restaurantID}
                  />
                )}
                restaurantID={restaurantID}
                elemsMaxHeight="55vh"
                bgColor="#fc1703"
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  完成
                  {ordersByState["done"] && (
                    <span> ({ordersByState["done"].length})</span>
                  )}
                </span>
              }
              key="done"
            >
              <DoneOrderCol
                orders={ordersByState["done"]}
                renderOrder={renderCollapsedOrder}
                renderOrderTitle={renderOrderTitle}
                renderOrderDetails={(order) => (
                  <OrderDetails
                    order={order}
                    restaurantID={restaurantID}
                  />
                )}
                elemsMaxHeight="55vh"
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  {">" + todayIntervalHours.toString() + "小時"}
                  {futurePendingOrders && (
                    <span> ({futurePendingOrders.length})</span>
                  )}
                </span>
              }
              key="pending-future"
            >
              <PendingOrderCol
                orders={futurePendingOrders}
                renderOrder={renderCollapsedOrder}
                renderOrderTitle={renderOrderTitle}
                renderOrderDetails={(order) => (
                  <OrderDetails
                    order={order}
                    pending
                    restaurantID={restaurantID}
                  />
                )}
                restaurantID={restaurantID}
                elemsMaxHeight="55vh"
                bgColor="#fc1703"
              />
            </TabPane>
          </Tabs>
        </Col>
        <Col span={16}>
          <StartedOrderCol
            orders={ordersByState["started"]}
            renderOrder={renderOrder}
            renderOrderTitle={renderOrderTitle}
            restaurantID={restaurantID}
            showItemSelect={true}
          />
        </Col>
      </Row>
    </Page>
  );
};
